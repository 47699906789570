import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

import { b3Header } from '@/utils';
import { tracerClient } from 'Utils/open-telemetry/open-telemetry-client';
import { getSnowplowUserData } from '@/utils/analytics/analytics-utils';
import { BFF_UAT_URL, REQUEST_HEADERS } from 'Api/constants';
import { cookies } from 'Data/constants';
import { getAccessToken, getSiteId, isLoggedIn, refreshAccessToken, saveTokens } from 'Utils/auth';
import Cookies from 'universal-cookie';

const cookiesLib = new Cookies();
export const baseURL = () => {
  if (!process.env.NEXT_PUBLIC_BFF_URL) {
    return BFF_UAT_URL;
  }
  return `${process.env.NEXT_PUBLIC_BFF_URL}/${getSiteId()}`;
};

const authHeader = isLoggedIn()
  ? { [REQUEST_HEADERS.AUTHORIZATION]: isLoggedIn() }
  : { [REQUEST_HEADERS.ACCESS_TOKEN]: getAccessToken() };

const client = axios.create({
  baseURL: baseURL(),
  headers: {
    ...authHeader,
    [REQUEST_HEADERS.SNOWPLOW_USER_ID]: getSnowplowUserData().domainUserId,
    [REQUEST_HEADERS.X_APIKEY]: process.env.NEXT_PUBLIC_BFF_API_KEY,
  },
});

client.interceptors.request.use((config) => {
  const enable_security_cookies = cookiesLib.get(cookies.ACTIVE_SECURITY_HEADERS);
  const BM_SZ = cookiesLib.get(cookies.BM_SZ);
  const ABCK = cookiesLib.get(cookies.ABCK);
  config.headers[cookies.ABCK] = enable_security_cookies ? ABCK : null;
  config.headers[cookies.BM_SZ] = enable_security_cookies ? BM_SZ : null;
  return config;
});

client.interceptors.request.use(
  (config) => {
    config.headers[REQUEST_HEADERS.REQUEST_ID] = uuidv4();
    config.headers[REQUEST_HEADERS.B3] = b3Header(tracerClient);
    if (['post', 'patch', 'put'].includes(config.method)) {
      config.headers[REQUEST_HEADERS.CONTENT_TYPE] = REQUEST_HEADERS.APPLICATION_JSON;
    }
    return config;
  },
  null,
  { synchronous: true }
);

// Add a response interceptor to refresh the JWT token if it's expired
client.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry && cookiesLib.get(cookies.SSO_REFRESH_TOKEN)) {
      originalRequest._retry = true;
      const tokens = await refreshAccessToken();
      if (tokens) {
        saveTokens(tokens);
        const { access_token } = tokens;
        originalRequest.headers.Authorization = `Bearer ${access_token}`;
        return client(originalRequest);
      }
    }
    return Promise.reject(error);
  }
);

export default client;
