import { type AppProps } from 'next/app';
import Script from 'next/script';
import { useEffect } from 'react';
import { useRouter } from 'next/router';

import Layout from '@/components/layout';
import { IViewDefinition } from '@/components/authored-page-routes/authored-page-routes.interface';
import { IKamFlag } from '@/store/kameleoonFlags/kameleoonFlags.interface';

import { initializeGTM } from '@/utils/analytics/google-tag-manager';
import { initializeAnalytics, trackEvent } from '@/utils/analytics/analytics-utils';
import { useKameleoonEngine } from '@/utils/kameleoon/use-kameleoon-engine';
import { initializeSalesfloorAccessibility } from '@/utils';
import { useUpdateAppBannerStyles } from '@/hooks';
import { fireworkTrackPageView, useFireworkAnalytics } from '@/utils/firework/firework-analytics';

import '../styles/globals.scss';

export interface PreRenderProps {
  viewDefinitionResults: {
    viewDefinition: IViewDefinition;
    currentUrlBase: string;
  } | null;
  themeTokens: object | null;
  cartData: object | null;
  kamFlags: Record<string, IKamFlag> | null;
}

async function enableMocking() {
  if (process.env.NODE_ENV !== 'development') {
    return;
  }

  if (typeof window !== 'undefined' && process.env.NEXT_PUBLIC_API_MOCKING === 'enabled') {
    const { worker } = await import('../mocks/browser');
    return worker.start({ onUnhandledRequest: 'bypass' });
  }
}

function MyApp({ Component, pageProps }: AppProps<PreRenderProps>) {
  const router = useRouter();

  enableMocking();
  const { cartData, viewDefinitionResults, kamFlags } = pageProps;

  // Apply banner style updates when the app banner is present
  useUpdateAppBannerStyles();

  useEffect(() => {
    initializeAnalytics();
    initializeSalesfloorAccessibility();
  }, []);

  useKameleoonEngine(); //experimentation tool - JS script supports visual editor experiments
  useFireworkAnalytics();

  useEffect(() => {
    // Get current page path
    const currentPath = router.pathname;

    // Track page view with correct page type

    switch (currentPath) {
      case '/shoppingbag':
        fireworkTrackPageView('shoppingbag');
        break;
      case '/purchase':
        fireworkTrackPageView('purchase');
        break;
    }

    // Track page view when route changes
  }, [router.pathname]); // Re-run when path changes

  return (
    <Layout cartData={cartData} viewDefinitionResults={viewDefinitionResults} kamFlags={kamFlags}>
      <Script
        src="https://www.googletagmanager.com/gtm.js?id=GTM-TCJKP3F"
        strategy="lazyOnload"
        defer
        onLoad={() => {
          initializeGTM();
        }}
      />
      <Script src="https://securepubads.g.doubleclick.net/tag/js/gpt.js" defer />
      <Component {...pageProps} />
    </Layout>
  );
}

export default MyApp;
