export const HELIOS_ANALYTICS_EVENT_TYPES = {
  HELIOS_ADD_TO_CART: 'heliosAddToCart',
  HELIOS_AD_TRACKING: 'heliosAdTracking',
  HELIOS_SORT_TRACKING: 'heliosSortTracking',
  HELIOS_FILTER_TRACKING: 'heliosFilterTracking',
  HELIOS_PRODUCT_CAROUSEL: 'heliosProductCarousel',
  HELIOS_PRODUCT_EVENT: 'heliosProductEvent',
  PRODUCT_CLICK: 'product_click',
  WAITING_ROOM_MODAL_VIEW: 'modal_view',
  HELIOS_NAV_CLICK: 'heliosNavClick',
  HELIOS_PAGE_TRACKING: 'heliosPageTracking',
  HELIOS_VERTICAL_FEATURE_CARDS: 'heliosVerticalFeatureCardContainer',
  HELIOS_HORIZONTAL_FEATURE_CARDS: 'heliosHorizontalFeatureCardContainer',
  HELIOS_BANNER_CAROUSEL: 'heliosBannerCarousel',
  HELIOS_REFRESH_LINKS_CLICK_TRACKING: 'refreshLinksClickTracking',
  HELIOS_PRODUCT_SEARCH_TRACKING: 'heliosProductSearchTracking',
  HELIOS_FORM_TRACKING: 'heliosFormTracking',
  HELIOS_ACQUISITION_FORM_SUBMIT: 'heliosAcquisitionFormSubmit',
  HELIOS_EXCEPTION_TRACKING: 'heliosExceptionTracking',
} as const;

export const SNOWPLOW = {
  PAGE_NAMES: {
    CATEGORY_PLP: 'category-plp',
    SUB_CATEGORY_PLP: 'sub-category-plp',
    SUB_SUB_CATEGORY_PLP: 'sub-sub-category-plp',
    SEARCH_PLP: 'search-plp',
    HOME_LANDING_PAGE: 'shop-landing',
    BEAUTY_LANDING_PAGE: 'beauty-landing',
    PERSONAL_CARE_LANDING_PAGE: 'personal-care-landing',
    HEALTH_LANDING_PAGE: 'health-landing',
    ELECTRONICS_LANDING_PAGE: 'electronics-landing',
    BABY_AND_CHILD_LANDING_PAGE: 'baby-and-child-landing',
    CHECKOUT_PAGE: 'purchase',
    ORDER_CONFIRMATION_PAGE: 'order-confirmation',
    PREVIOUS_PURCHASE: 'previous-purchases',
    CONTACT_US: 'contact-us',
    SIGN_UP: 'sign-up',
  },
  PAGE_TEMPLATES: {
    DEPARTMENT_TEMPLATE: 'department-landing',
    PLP_TEMPLATE: 'plp',
  },
  PAGE_PATH: {
    SEARCH: '/search',
    CATEGORIES: '/categories/',
    COLLECTIONS: '/collections/',
    CATEGORIES_ABBR: '/c/',
    PDP: '/p/',
  },
  PAGE_SECTIONS: {
    LANDING: 'landing-page',
    CONTENT: 'content-page',
  },
  EVENT_NAMES: {
    PRODUCT_CLEAR: 'product_clear',
    FILTER_SORT_CLEAR: 'filter_sort_clear',
    AD_CLEAR: 'ad_clear',
    COMPONENT_CLEAR: 'component_clear',
    UI_ENGAGEMENT: 'ui_engagement',
    PRODUCT_PAGINATION_CLEAR: 'product_pagination_clear',
    PRODUCT_LISTING: 'product_listing',
    SEARCH_CLEAR: 'search_clear',
    FORM_SUBMIT: 'form_submit',
    MODAL_VIEW: 'modal_view',
    APPLY_FILTER: 'apply_filter',
  },
  ENV: {
    PROD: 'prod',
    DEV: 'dev',
    QA: 'qa',
  },
  APP_ID: {
    PROD: 'beauty-web-prod',
    DEV: 'beauty-web-dev',
    QA: 'beauty-web-qa',
  },
} as const;

export const PAGE_CMS = {
  CONTENTFUL_PAGE: 'contentful',
} as const;

export const MEDIA_PIXELS = {
  ADD_TO_BAG: 'add-to-bag',
  ADD_TO_BAG_OPEN: 'add-to-bag-open',
  CUSTOM_LINK: 'customLink',
} as const;

export const USER_CONTEXT = {
  REGISTRATION_STATUS: {
    UNKNOWN: 'unknown',
    REGISTERED: 'registered',
  },
} as const;

export const mockGlobalContextFields = {
  cartCount: 0,
  locale: 'en',
  currentDevice: '',
  pageId: '',
  pcOptimumAccount: {
    memberId: '',
    walletId: '',
    householdId: '',
    points: {
      balance: 0,
      dollarsRedeemable: 0,
      dollarsRedeemedLifetime: 0,
    },
  },
};

export const HELIOS_COMPONENT_NAMES = {
  PRODUCT_GRID_COMPONENT: 'productGridComponent',
};
