import { useMutation, useQueryClient } from '@tanstack/react-query';
import { fireworkTrackAddToCart } from '@/utils/firework/firework-analytics';

import { QUERY_KEYS } from 'Api/constants';
import client from 'Api/datasources/bff-datasources';
import { getExistingCartGuid, getLoggedInStatus, isLoggedIn } from 'Utils/auth';

const userStatus = () => getLoggedInStatus().toLowerCase();
const cartUrlParam = () => (isLoggedIn() ? userStatus() : getExistingCartGuid());
const cartUrl = () => `/users/${userStatus()}/carts/${cartUrlParam()}`;

export const addToCartReducer = (data: any, guid = '') => {
  const { entry, subtotal } = data;
  return {
    success: true,
    message: `Cart item ${entry?.product?.code} has been added`,
    cart: {
      id: guid,
      subTotal: {
        currencyIso: subtotal?.currencyIso,
        formattedValue: subtotal?.formattedValue,
        value: parseFloat(subtotal?.value),
      },
      entryNumber: entry?.entryNumber,
    },
  };
};

export const getCart = async () => {
  const params = { loyaltyInfo: 'simple' };
  const response = await client.get(`${cartUrl()}`, { params });
  return response?.data;
};

export const createCart = async () => {
  const response = await client.post(`/users/${userStatus()}/carts`);
  return response?.data;
};

export const addToCart = async (productId: any, quantity: any, guid = getExistingCartGuid()) => {
  const guidParam = isLoggedIn() ? userStatus() : guid;
  const params = { code: productId, qty: quantity };
  const response = await client.post(`/users/${userStatus()}/carts/${guidParam}/entries`, {}, { params });

  const { entry } = response?.data || {};
  const { product } = entry || {};

  if (product) {
    fireworkTrackAddToCart(product, entry.quantity);
  }

  return addToCartReducer(response?.data, guid);
};

export const useAddItemToCart = (mutationOptions = {}) => {
  const {
    mutate: addItemToCart,
    isPending: loading,
    error,
    data,
  } = useMutation({
    mutationFn: ({ productId, quantity, guid }: { productId: any; quantity: any; guid?: any }) =>
      addToCart(productId, quantity, guid),
    ...mutationOptions,
  });
  return { addItemToCart, loading, error, data };
};

export const useCreateAndAddItemToCart = (mutationOptions = {}) => {
  const {
    addItemToCart,
    loading: addItemToCartLoading,
    error: addItemToCartError,
    data: addItemToCartData,
  } = useAddItemToCart(mutationOptions);
  const {
    mutate: createAndAddToCart,
    isPending: loading,
    error,
  } = useMutation({
    mutationFn: () => createCart(),
    onSuccess: (data, payload: any) => {
      addItemToCart({ ...payload, guid: data?.guid });
    },
  });
  return {
    createAndAddToCart,
    addItemToCart,
    loading: loading || addItemToCartLoading,
    error: error || addItemToCartError,
    data: addItemToCartData,
  };
};

export const useAddToCart = (mutationOptions = {}) => {
  const { createAndAddToCart, addItemToCart, loading, error, data } = useCreateAndAddItemToCart(mutationOptions);
  const queryClient = useQueryClient();
  const addToCart = async ({ productId, quantity }: { productId: any; quantity: any }) => {
    const guestGuid = getExistingCartGuid();
    if (isLoggedIn()) {
      try {
        await queryClient.fetchQuery({
          queryKey: [QUERY_KEYS.CART],
          queryFn: () => getCart(),
          staleTime: 10 * 60 * 1000,
        });
        addItemToCart({ productId, quantity });
      } catch (error) {
        createAndAddToCart({ productId, quantity });
      }
    } else {
      if (guestGuid) {
        addItemToCart({ productId, quantity });
      } else {
        createAndAddToCart({ productId, quantity });
      }
    }
  };
  return {
    addToCart,
    loading,
    error,
    data,
  };
};
